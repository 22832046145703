import React, { Component } from 'react';
import Carousel from '../component/carouselFullPage';

class Home extends Component {
  render(){
    return (
        <React.Fragment>
            <Carousel/>
        </React.Fragment>
    );
  }
}

export default Home;